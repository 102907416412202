import React, {Component} from "react";
import SortingChevron from "src/util/SortingChevron.jsx";
import SoegeForm from "src/pages/SoegeForm.jsx";
import {constants} from "src/util/constants.js";
import {Link} from "react-router-dom";
import Pagination from "src/pages/Pagination.jsx";
import CloseButton from "src/util/CloseButton.jsx";

class OrganisationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organisationerHentet: false,
      activeSort: "",
      sortDirection: "",
      soeger: false,
      soegteOrgansaitioner: [],
      soegning: "",
      harSoegt: false,
      side: 0
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.state.organisationerHentet && this.props.visModal) {
      this.props.hentOrganisationer();
      this.setState({organisationerHentet: true})
    }
  }

  sort(organisationer, activeSort, sortDirection) {
    const sortedOrganisationer = organisationer ? organisationer.sort((a, b) => {
      if (this.state.searching)
        return -1;
      if (activeSort === "instnr")
        return a.kode.localeCompare(b.kode);
      if (activeSort === "navn")
        return a.navn.localeCompare(b.navn);
      if (activeSort === "cvr")
        return !a.cvr ? -1 : !b.cvr ? 1 : a.cvr.toString().localeCompare(b.cvr.toString());
      if (activeSort === "pnr")
        return !a.pnr ? -1 : !b.pnr ? 1 : a.pnr.toString().localeCompare(b.pnr.toString());
      return 0;
    }) : [];

    if (sortDirection === "asc")
      sortedOrganisationer.reverse();

    this.setState({harSoegt: true, soegteOrgansaitioner: sortedOrganisationer, activeSort: activeSort, sortDirection: sortDirection})
  }

  soeg(organisationer, soegning) {
    const query = soegning.toLowerCase();
    this.setState({soeger: true });
    const soegteOrgansiationer = organisationer.filter(organisation =>
            organisation.kode.toLocaleLowerCase().search(query) !== -1 || //NOSONAR
            organisation.navn.toLocaleLowerCase().search(query) !== -1 ||
            (organisation.pnr && organisation.pnr.toString().search(query) !== -1) ||
            (organisation.cvr && organisation.cvr.toString().search(query) !== -1)
        ).sort((a, b) => {
          const aSearchValue = Math.min([a.kode.toLocaleLowerCase().search(query),
            a.navn.toLocaleLowerCase().search(query),
            (a.pnr ? a.pnr.toString().search(query) : -1),
            (a.cvr ? a.cvr.toString().search(query) : -1)].filter(searchValue => searchValue !== -1));

          const bSearchValue = Math.min([b.kode.toLocaleLowerCase().search(query),
            b.navn.toLocaleLowerCase().search(query),
            (b.pnr ? b.pnr.toString().search(query) : -1),
            (b.cvr ? b.cvr.toString().search(query) : -1)].filter(searchValue => searchValue !== -1));

          return aSearchValue - bSearchValue;
        })
    this.setState({soeger: false, harSoegt: true, soegteOrgansaitioner: soegteOrgansiationer, side: 0});
  }

  render() {
    const {status, bruger, visModal, lukModal, organisationer, skiftOrganisation} = this.props;

    const visteOrganisationer = this.state.harSoegt ? this.state.soegteOrgansaitioner : organisationer;
    const sidsteside = Math.ceil(visteOrganisationer.length / constants.konfiguration.organsiationModal.organisationerPrSide);
    const startIndex = this.state.side * constants.konfiguration.organsiationModal.organisationerPrSide;
    const endIndex = (this.state.side + 1) * constants.konfiguration.organsiationModal.organisationerPrSide;

    const loading = status === constants.status.henterOrganisationer || status === constants.status.skifterOrganisation;

    return (
        <div>
          <div className="fds-modal" id="modal-example" aria-hidden={!visModal} role="dialog"
               aria-modal="true" aria-labelledby="modal-example-title">
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title" id="modal-example-title">Skift organisation</h2>
                <CloseButton lukModal={lukModal} disabled={!bruger.organisation || loading}/>
              </div>
              <div className="modal-body">
                <SoegeForm soeg={(soegning) => this.soeg(organisationer, soegning)}
                           soeger={this.state.soeger}
                           titel={"Søg efter organisation"}
                           placeholder={"Søg evt. efter institutionsnummer, institutionsnavn eller CVR-nummer"}
                           ryd={() => this.setState({soegning: "", harSoegt: false})}/>

                {loading ? <div className="spinner"></div> : null}

                <OrgansiationsTabel organisationer={visteOrganisationer.slice(startIndex, endIndex)}
                                    activeSort={this.state.activeSort}
                                    sortDirection={this.state.sortDirection}
                                    sort={(activeSort) => this.sort(visteOrganisationer, activeSort, this.state.sortDirection === "asc" ? "desc" : "asc")}
                                    skiftOrganisation={skiftOrganisation}/>

                <Pagination side={this.state.side}
                            sidsteSide={sidsteside}
                            soeg={(soegestreng, sortfelt, sortdirection, side) => this.setState({side: side})}
                            soegestreng={this.state.soegning}
                            sortfelt={this.state.activeSort}
                            sortdirection={this.state.sortDirection} />
              </div>
              <div className="modal-footer">
                <button className="button button-secondary" onClick={lukModal} disabled={!bruger.organisation || loading}>
                  Luk
                </button>

                <Link to={constants.navigation.urlLogout}>
                  <button className="button button-secondary" disabled={loading}>
                    Log ud
                  </button>
                </Link>
              </div>

            </div>
          </div>

          { visModal && <div className="modal-backdrop fade show"></div> }
        </div>
    );
  }
}

const OrgansiationsTabel = (props) => {
  return (
      <div className="table--responsive-scroll">
        <table className="test-table stil-folding-table margtop10">
          <thead>
          <tr>
            <th id="header-isntnr" className="hand" onClick={() => props.sort("instnr")} title="Sortér på institutionsnummer">
              Institutionsnummer&nbsp;
              <SortingChevron active={props.activeSort === "instnr"} direction={props.sortDirection}/>
            </th>

            <th id="header-navn" className="hand" onClick={() => props.sort("navn")} title="Sortér på institutionsnavn">
              Institutionsnavn&nbsp;
              <SortingChevron active={props.activeSort === "navn"} direction={props.sortDirection}/>
            </th>

            <th id="header-cvr" className="hand" onClick={() => props.sort("cvr")} title="Sortér på CVR">
              CVR&nbsp;
              <SortingChevron active={props.activeSort === "cvr"} direction={props.sortDirection}/>
            </th>

            <th id="header-pnr" className="hand" onClick={() => props.sort("pnr")} title="Sortér på P-nummer.">
              P-nr.&nbsp;
              <SortingChevron active={props.activeSort === "pnr"} direction={props.sortDirection}/>
            </th>
            <th>&nbsp;</th>
          </tr>
          </thead>
          <tbody>
          {
            props.organisationer.map(organisation => {
              return (
                  <tr key={`${organisation.type}-${organisation.kode}`} className="hand" onClick={() => props.skiftOrganisation(organisation)}>
                    <td>{organisation.kode}</td>
                    <td>{organisation.navn}</td>
                    <td>{organisation.cvr}</td>
                    <td>{organisation.pnr}</td>
                    <td>
                      <img id="skift-organisation" title="Skift til organisation" src="img/right.svg" alt="Skift til organisation" style={{cursor: 'pointer'}} />
                    </td>
                  </tr>
              );
            })
          }
          </tbody>
        </table>
      </div>
  )
}

export default OrganisationModal

