
  export function isEmptyStr(obj) {
    return obj === null || obj === undefined || obj.trim() === "";
  }

  export function fill(tal) {
    if (tal < 10)
      return "0" + tal;
    return "" + tal;
  }

  export function formatTimestamp(timestamp) {
    if (timestamp === null)
      return "";
    const dato = new Date(timestamp);
    return fill(dato.getDate()) + "-" + fill(dato.getMonth() + 1) + "-" + fill(dato.getFullYear()) + " " + fill(dato.getHours()) + ":" + fill(dato.getMinutes());
  }

  export function naIfEmpty(val) {
    if (val === "")
      return "n/a";
    return val;
  }
