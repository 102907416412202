import React, {Component} from 'react'
import {formatTimestamp} from 'src/util/util.js'

class SoegeBruger extends Component {

  hentAktoer(aktoerUuid, type) {

    const { hentElev, hentMedarbejder, hentEkstern, hentKontaktperson } = this.props;

    if (type === "Elev") {
      hentElev(aktoerUuid);
    } else if (type === "Medarbejder") {
      hentMedarbejder(aktoerUuid);
    } else if (type === "Ekstern") {
      hentEkstern(aktoerUuid);
    } else if (type === "Kontaktperson") {
      hentKontaktperson(aktoerUuid);
    }

  }

  render() {
    const {soegebruger} = this.props;

    let title = "Vis elev detaljer";
    if (soegebruger.type === "Medarbejder") {
      title = "Vis medarbejder detaljer";
    } else if (soegebruger.type === "Ekstern") {
      title = "Vis ekstern detaljer";
    } else if (soegebruger.type === "Kontaktperson") {
      title = "Vis kontaktperson detaljer";
    }

    return (
      <tr>
        <td>{soegebruger.fornavn}</td>
        <td>{soegebruger.efternavn}</td>
        <td>{soegebruger.uniId}</td>
        <td>{soegebruger.klasse}</td>
        <td>{soegebruger.type}</td>
        <td>{formatTimestamp(soegebruger.aendretTs)}</td>
        <td className="bruger-actions">
          <img className="detaljer-eye" title={title} src="img/ic_visibility_24px.svg"
               alt={title} style={{cursor: 'pointer'}} onClick={() => this.hentAktoer(soegebruger.aktoerUuid, soegebruger.type)}/>
        </td>
      </tr>
    );
  }
}

export default SoegeBruger;