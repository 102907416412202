import React, {Component} from 'react'
import Gruppe from "src/pages/Gruppe.jsx";

class GrupperView extends Component {

  render() {

    const {grupper} = this.props;

    if (grupper === null || grupper === undefined || grupper.length === 0) {
      return null;
    }

    return (
        <div>
        <h3>Grupper</h3>
        <table className="test-table stil-folding-table">
          <thead>
          <tr>
            <th id="header-gruppekode">Gruppekode</th>
            <th id="header-navn">Navn</th>
            <th id="header-gruppetype">Gruppetype</th>
            <th id="header-klassetype">Klassetype</th>
            <th id="header-gruppetrin">Gruppetrin</th>
            <th id="header-oprettet">Oprettet</th>
            <th id="header-aendret">Sidst ændret</th>
          </tr>
          </thead>
          <tbody>
          {
            grupper.map(gruppe => {
              return <Gruppe key={gruppe.gruppe_uuid} gruppe={gruppe}/>
            })
          }

          </tbody>
        </table>
        </div>
    );
  }
}

export default GrupperView;