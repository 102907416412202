import React, {Component} from "react";
import AktoerView from "src/pages/AktoerView.jsx";
import GrupperView from "src/pages/GrupperView.jsx";
import CloseButton from "src/util/CloseButton.jsx";
import FeedbackBox from "src/util/FeedbackBox.jsx";
import BeskyttetDataComponent from "src/pages/BeskyttetDataComponent.jsx";

class MedarbejderModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            erBrugeradministrator: false,
            feedbackBesked: null
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.medarbejder !== this.props.medarbejder && this.props.visModal) {
            this.setState({erBrugeradministrator: this.props.medarbejder.erBrugeradministrator})
            this.setState({feedbackBesked: null})
        }
        if (prevProps.detaljesideAlerts !== this.props.detaljesideAlerts && this.props.visModal && this.props.detaljesideAlerts.fejl.length > 0) {
            if (this.props.detaljesideAlerts.fejl.length > 0) {
                this.setState({feedbackBesked: this.props.detaljesideAlerts.fejl})
            }
        }
        if (prevProps.aktoerErBrugeradm !== this.props.aktoerErBrugeradm && this.props.aktoerErBrugeradm != null && this.props.detaljesideSuccess.success.length > 0) {
            this.setState({erBrugeradministrator: this.props.aktoerErBrugeradm.aktoerErBrugeradm})
            this.setState({feedbackBesked: this.props.detaljesideSuccess.success})
        }
    }

    opdaterBrugeradm(e, medarbejder) {
        const {opretBrugeradministrator, sletBrugeradministrator} = this.props;
        e.preventDefault();

        this.setState({feedbackBesked: null})

        if (this.state.erBrugeradministrator === true) {
            sletBrugeradministrator(medarbejder.aktoer_uuid)
        } else {
            opretBrugeradministrator(medarbejder.aktoer_uuid)
        }
    }

  render() {
    const {visModal, lukModal, medarbejder, erSupport} = this.props;
    let medarbejderNavn;

    if (!visModal)
        return null;

    if(visModal){
        medarbejderNavn = medarbejder.fornavn + " " + medarbejder.efternavn;
    }

        return (
            <div>
                <div className="fds-modal" id="medarbejder-modal" aria-hidden={!visModal} role="dialog"
                     aria-modal="true" aria-labelledby="modal-example-title">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title" id="modal-example-title">Medarbejder:  <BeskyttetDataComponent erSupport={erSupport} data={medarbejderNavn} erBeskyttet={medarbejder.beskyttet} erOverskrift={true}/></h2>
                            <CloseButton lukModal={lukModal}/>
                        </div>
                        <div className="modal-body">
                            <div className="modalcontent">
                                <div className="stil-col">
                                    <AktoerView aktoer={medarbejder} erSupport={erSupport}/>
                                    <div className="line">
                                        <div className="label">Roller</div>
                                        <div className="field">{medarbejder.roller.join(", ")}</div>
                                    </div>
                                    {medarbejder.erBrugeradministrator != null &&
                                        <div className="line">
                                            <div className="label">Brugeradministrator</div>
                                            <input type="checkbox" checked={this.state.erBrugeradministrator}
                                                   onChange={(e) => this.opdaterBrugeradm(e, medarbejder)}/>
                                            {this.state.feedbackBesked != null &&
                                                <FeedbackBox feedbackBesked={this.state.feedbackBesked}
                                                             detaljesideAlerts={this.props.detaljesideAlerts}></FeedbackBox>
                                            }
                                        </div>
                                    }
                                    <GrupperView grupper={medarbejder.grupper}/>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="buttongroup">
                                <button id="medarbejder-close-button" className="margleft10 stil-primary-button button"
                                        onClick={lukModal}
                                        title="Lukker dialogen">
                                    Luk
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

                {visModal && <div className="modal-backdrop fade show"></div>}
            </div>
        )
    }
}

export default MedarbejderModal

