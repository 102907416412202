import React, {Component} from 'react'
import SortingChevron from "src/util/SortingChevron";
import {constants} from 'src/util/constants.js';
import SoegeForm from "src/pages/SoegeForm.jsx";
import SoegeBruger from "src/pages/SoegeBruger.jsx";
import AlertBar from "src/util/AlertBar.jsx";
import Pagination from "src/pages/Pagination.jsx";
import ElevModal from "src/modals/ElevModal.jsx";
import EksternModal from "src/modals/EksternModal.jsx";
import MedarbejderModal from "src/modals/MedarbejderModal.jsx";

class SoegeMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSort: "",
      sortDirection: ""
    };
  }

  componentDidMount() {
    let elmnt = document.getElementById("brugere-header");
    if (elmnt) {
      elmnt.scrollTop = 200;
    }
  }

  componentDidUpdate() {
    let elmnt = document.getElementById("brugere-header");
    if (elmnt) {
      elmnt.scrollIntoView(true);
    }
  }

  sort(soegebrugere, field) {

    const {soegning, soeg} = this.props;

    if (this.state.activeSort === field && this.state.sortDirection === 'asc') {
      this.setState({
        activeSort: field,
        sortDirection: "desc",
      });
      soeg(soegning.soegestreng, field, "desc", 0)
    } else {
      this.setState({
        activeSort: field,
        sortDirection: "asc",
      });
      soeg(soegning.soegestreng, field, "asc", 0)
    }
  }

  render() {

    const {hentElev, hentMedarbejder, hentEkstern, skjulAktoerModal, soegning, soeg, login, opretBrugeradministrator, sletBrugeradministrator} = this.props;
    let placeholder = (login.bruger && login.bruger.erSupport) ? "Søg evt. efter navn, uni-id, CPR eller klasse" : "Søg evt. efter navn, uni-id eller klasse";
    let soegEfterBrugere = "Søg efter brugere";

    if (soegning.status === constants.status.soegSuccesIngenFundet) {
      return (
        <div>
          <section className="margbottom10 margtop30">
            <SoegeForm id="brugere-search" soeg={soeg}
                       soeger={soegning.status === constants.status.soeger}
                       titel={soegEfterBrugere}
                       placeholder={placeholder}  />
          </section>
          <div className="stil-full-width" />
          <section className="margbottom10 margtop30">
            <AlertBar alerts={soegning.alerts}/>
          </section>
        </div>
      );
    }

    if (soegning.soegebrugere.length === 0) {
      return (
        <div className="margtop30">
          <section>
            <AlertBar alerts={soegning.alerts}/>
          </section>
          <section className="margbottom10">
            <SoegeForm id="brugere-search" soeg={soeg}
                       soeger={soegning.status === constants.status.soeger}
                       titel={soegEfterBrugere}
                       placeholder={placeholder}/>
          </section>
        </div>
      );
    }

    return (
      <div>
        <div className="stil-full-width">
          <section>
            <AlertBar alerts={soegning.alerts}/>
          </section>
          <div className="margtop30"/>
          <section>
            <SoegeForm id="brugere-search" soeg={soeg}
                       soeger={soegning.status === constants.status.soeger}
                       titel={soegEfterBrugere}
                       placeholder={placeholder}/>
          </section>
          <div className="margbottom10"/>
        </div>

        <div>
          <section>
            <div className="stil-col">
              <h1 id="brugere-header" className="margtop30">Brugere</h1>
              <table className="test-table stil-folding-table margtop10" id={"aktoer-table"}>
                <thead>
                <tr>
                  <th id="header-fornavn" className="hand" onClick={() => this.sort(soegning.soegebrugere, "fornavn")}
                      title="Sortér på fornavn">
                    Fornavn&nbsp;
                    <SortingChevron active={this.state.activeSort === "fornavn"} direction={this.state.sortDirection}/>
                  </th>

                  <th id="header-efternavn" className="hand" onClick={() => this.sort(soegning.soegebrugere, "efternavn")}
                      title="Sortér på efternavn">
                    Efternavn&nbsp;
                    <SortingChevron active={this.state.activeSort === "efternavn"}
                                    direction={this.state.sortDirection}/>
                  </th>

                  <th id="header-uni-id" className="hand" onClick={() => this.sort(soegning.soegebrugere, "uniid")}
                      title="Sortér på UNI-ID">
                    UNI-ID&nbsp;
                    <SortingChevron active={this.state.activeSort === "uniid"} direction={this.state.sortDirection}/>
                  </th>

                  <th id="header-klasse" className="hand" onClick={() => this.sort(soegning.soegebrugere, "klasse")}
                      title="Sortér på klasse">
                    Klasse&nbsp;
                    <SortingChevron active={this.state.activeSort === "klasse"} direction={this.state.sortDirection}/>
                  </th>

                  <th>Type</th>

                  <th>Sidst ændret</th>

                  <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                {
                  soegning.soegebrugere == null ? null :
                      soegning.soegebrugere.map(soegebruger => {
                      return <SoegeBruger key={soegebruger.aktoerUuid} soegebruger={soegebruger}
                                          hentElev={hentElev} hentMedarbejder={hentMedarbejder}
                                          hentEkstern={hentEkstern}
                      />
                    })
                }

                </tbody>
              </table>
              <Pagination side={soegning.side} sidsteSide={soegning.sidsteside} soeg={soeg} soegestreng={soegning.soegestreng} sortfelt={soegning.sortfelt} sortdirection={soegning.sortdirection} />
            </div>
            <ElevModal elev={soegning.elev}
                       visModal={soegning.elev !== null}
                       erSupport={login.bruger.erSupport}
                       lukModal={skjulAktoerModal}/>

            <MedarbejderModal medarbejder={soegning.medarbejder}
                              visModal={soegning.medarbejder !== null}
                              erSupport={login.bruger.erSupport}
                              lukModal={skjulAktoerModal}
                              opretBrugeradministrator={opretBrugeradministrator}
                              sletBrugeradministrator={sletBrugeradministrator}
                              aktoerErBrugeradm={soegning.aktoerErBrugeradm}
                              detaljesideAlerts={soegning.detaljesideAlerts}
                              detaljesideSuccess={soegning.detaljesideSuccess}
            />

            <EksternModal ekstern={soegning.ekstern}
                          visModal={soegning.ekstern !== null}
                          erSupport={login.bruger.erSupport}
                          lukModal={skjulAktoerModal}
                          opretBrugeradministrator={opretBrugeradministrator}
                          sletBrugeradministrator={sletBrugeradministrator}
                          aktoerErBrugeradm={soegning.aktoerErBrugeradm}
                          detaljesideAlerts={soegning.detaljesideAlerts}
                          detaljesideSuccess={soegning.detaljesideSuccess}
            />

          </section>
        </div>
      </div>
    );
  }
}

export default SoegeMain;

