import React from 'react';

const Footer = () => {
  const items = [{
    title: 'Styrelsen for It og Læring'
  }, {
    title: 'Support',
    url: 'https://jira.stil.dk/servicedesk/customer/portal/7/create/220'
  }, {
    title: 'Hjælp til brugeroversigten',
    url: 'https://viden.stil.dk/display/STILVIDENOFFENTLIG/Brugeroversigten'
  }, {
    title: 'Beskyttelse af personoplysninger',
    url: 'https://viden.stil.dk/display/OFFGDPR/Unilogin'
  }].map((item, i, items) => {
    const content = item.url != null ? React.createElement("a", {
      href: item.url
    }, item.title) : React.createElement("span", {
      style: {
        fontWeight: 600
      }
    }, item.title);
    const className = i !== items.length - 1 ? "bullet" : null;
    return React.createElement("span", {
      key: i,
      className: className
    }, content);
  });
  return React.createElement("footer", null, React.createElement("div", {
    className: "footer"
  }, items));
};

export default Footer;