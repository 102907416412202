import React from "react";

const CloseButton = (props) => {
  return <button className="modal-close button button-unstyled" aria-label="Luk" onClick={props.lukModal}
                 disabled={props.disabled}>
    <svg id="close" className="icon-svg" viewBox="0 0 24 24" width="100%" height="100%">
      <path
          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
    </svg>
  </button>
}

export default CloseButton;