import {useEffect} from 'react'
import {constants} from "src/util/constants.js";
import {useLocation} from "react-router-dom";

function LoginMain(props)  {
  const { pathname } = useLocation();

  useEffect(() => {
    const {loginClear} = props;

    if(pathname === constants.navigation.urlLogin){
      loginClear();
      window.location.replace(constants.navigation.urlBELogin);
    }

    if(pathname === constants.navigation.urlLogout){
      loginClear();
      window.location.replace(constants.navigation.urlBELogout);
    }

  }, [pathname, props]);

return null;
}

export default LoginMain;
