import {connect} from 'react-redux'
import * as loginActions from "src/actions/login.js";
import LoginMain from "src/containers/LoginMain.jsx";

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps  = (dispatch) => {
  return {
    loginClear: () => dispatch(loginActions.loginClear())
  };
};

const LoginConnector = connect(
  mapStateToProps,
  mapDispatchToProps
)((LoginMain));

export default LoginConnector;
