import {applyMiddleware, createStore} from 'redux';
import {thunk} from 'redux-thunk';
import {persistReducer, persistStore} from 'redux-persist';
// Vigtigt, at det er session storage: persistent kun for den pågældende tab.
import storage from 'redux-persist/lib/storage/session';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import getAuthState from 'src/util/getAuthState';
import rootReducer from 'src/reducers';

// Oprindelig begrundelse for denne kode:
// Opsættes med persistent state, så siden kan reloades uden at miste JWT-token.
// https://github.com/rt2zz/redux-persist
// https://blog.reactnativecoach.com/the-definitive-guide-to-redux-persist-84738167975
// Men nu er token bundet op på en cookie. Der kan dog stadig være fordele ved at gemme state i
// session storage i forhold til at beholde state ved refresh.

// 2x noinspection: Idea kan ikke finde ud af at tolke stateReconciler korrekt.
// noinspection JSUnusedGlobalSymbols
const persistConfig = {
  storage,
  key: 'root',
  stateReconciler: autoMergeLevel2,
  whitelist: ['login', 'forside'],
  // Man kan migrere, så state bringes i den korrekte tilstand, inden det merges med initial state.
  // Vores migration består i, at token i query param skal overskrive en eventuel null i state.
  // Ved at ignorere versionering kan vi få dette til at køre hver gang, siden indlæses.
  // https://github.com/rt2zz/redux-persist/blob/master/docs/migrations.md
  // TODO: Vi overskriver altid login-state. Man skal ikke vise en eventuelt udløbet session, hvis
  //       siden genindlæses! Den oprindelige begrundelse af persistering af state var, at man
  //       ellers blev logget ud, hvis man genindlæste siden, men nu har vi cookies til at sikre
  //       login. Men det vil kræve noget arbejde at skrive funktionen ud af state-persistering.
  //       Persistering af login-state i delsystemerne sikrer p.t. at systemet ved, at den er logget
  //       ind, men her skulle den hellere bruge samme funktion som getAuthState og i øvrigt udnytte
  //       persistering til at sikre formulardata ved genindlæsning af siden.
  migrate: (state) => {
    // noinspection ES6ModulesDependencies
    return Promise.resolve(getAuthState(state));
  }
};

// noinspection JSCheckFunctionSignatures
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore(initialState) {

  const store = createStore(
    persistedReducer,
    initialState,
    applyMiddleware(thunk)
  );

  const persistor = persistStore(store);

  return { store, persistor };
}
