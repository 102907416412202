import {constants} from "src/util/constants.js";
import fetchJson from "src/util/fetch.js";

export function soeg(soegestreng, sortfelt, sortdirection, side) {

  if (sortfelt === null || sortfelt === undefined || sortfelt === "")
    sortfelt = "fornavn";

  return (dispatch, getState) => {
    const {login} = getState();

    var tmpUrl = `${constants.api.soegning}?soegning=${soegestreng.toLowerCase()}&side=${side}&sorteringsfelt=${sortfelt}&sortering=${sortdirection}`;
    var url = tmpUrl.replace("{instnr}", login.bruger.organisation.kode);

    dispatch(soeger(soegestreng, sortfelt, sortdirection, side));
    fetchJson(url, 'GET')
      .then((item) => {
        dispatch(soegSucces(item))
      })
      .catch((errorResponse) => {
        if (errorResponse.status === 401)
          dispatch(soegFejl(["401"]));
        else
          dispatch(soegFejl(["Søgningen er fejlet, prøv igen senere."]));
      });
  };
}

function soeger(soegestreng, sortfelt, sortdirection, side) {
  return {
    type: constants.action.SOEGER,
    soegestreng: soegestreng,
    sortfelt: sortfelt,
    sortdirection: sortdirection,
    side: side
  }
}

function soegSucces(response) {

  var info = response.brugere.length === 0 ? ["Din søgning gav desværre ingen resultater"] : [];

  return {
    type: constants.action.SOEG_SUCCES,
    soegebrugere: response.brugere,
    side: response.side,
    sidsteside: response.totalAntalSider,
    alerts: {fejl: [], info: info}
  }
}

function soegFejl(errors) {
  return {
    type: constants.action.SOEG_FEJL,
    alerts: {fejl: errors, info: []}
  }
}

export function brugerSoegningSupport(soegestreng, sortfelt, sortdirection, side) {
  if (sortfelt === null || sortfelt === undefined || sortfelt === "")
    sortfelt = "sidstAendret";

  return (dispatch) => {
    dispatch(brugerSoegningSupportSoeger(soegestreng, sortfelt, sortdirection, side));
    fetchJson(constants.api.brugerSoegningSupport, 'POST', {
      soegestreng: soegestreng.toLowerCase(), page:side, sortingField: sortfelt, sortingDirection: sortdirection
    })
        .then((item) => {
          dispatch(brugerSoegningSupportSucces(item))
        })
        .catch((errorResponse) => {
          if (errorResponse.status === 401)
            dispatch(brugerSoegningSupportFejl(["401"]));
          else
            dispatch(brugerSoegningSupportFejl(["Søgningen er fejlet, prøv igen senere."]));
        });
  };
}

function brugerSoegningSupportSoeger(soegestreng, sortfelt, sortdirection, side) {
  return {
    type: constants.action.BRUGER_SOEGER_SUPPORT,
    soegestreng: soegestreng,
    sortfelt: sortfelt,
    sortdirection: sortdirection,
    side: side
  }
}

function brugerSoegningSupportSucces(response) {

 let info = response.brugere.length === 0 ? ["Din søgning gav desværre ingen resultater"] : [];

  return {
    type: constants.action.BRUGER_SOEG_SUPPORT_SUCCES,
    soegebrugere: response.brugere,
    historikListe: response.historik,
    side: response.side,
    sidsteside: response.totalAntalSider,
    alerts: {fejl: [], info: info}
  }
}

function brugerSoegningSupportFejl(errors) {
  return {
    type: constants.action.BRUGER_SOEG_SUPPORT_FEJL,
    alerts: {fejl: errors, info: []}
  }
}


export function hentElev(aktoerUuid) {

  return dispatch => {

    var url = `${constants.api.hentElevUrl}`.replace("{aktoerUuid}", aktoerUuid);

    dispatch(henterElev());
    fetchJson(url, 'GET')
      .then((item) => {
        dispatch(hentElevSucces(item))
      })
      .catch((errorResponse) => {
        if (errorResponse.status === 401)
          dispatch(hentElevFejl(["401"]));
        else
          dispatch(hentElevFejl(["Hentning af elev er fejlet, prøv igen senere."]));
      });
  };
}

function henterElev() {
  return {
    type: constants.action.HENTER_ELEV
  }
}

function hentElevSucces(response) {
  return {
    type: constants.action.HENT_ELEV_SUCCES,
    elev: response
  }
}

function hentElevFejl(errors) {
  return {
    type: constants.action.HENT_ELEV_FEJL,
    alerts: {fejl: errors, info: []}
  }
}

export function hentMedarbejder(aktoerUuid) {

  return dispatch => {

    var url = `${constants.api.hentMedarbejderUrl}`.replace("{aktoerUuid}", aktoerUuid);

    dispatch(henterMedarbejder());
    fetchJson(url, 'GET')
      .then((item) => {
        dispatch(hentMedarbejderSucces(item))
      })
      .catch((errorResponse) => {
        if (errorResponse.status === 401)
          dispatch(hentMedarbejderFejl(["401"]));
        else
          dispatch(hentMedarbejderFejl(["Hentning af medarbejder er fejlet, prøv igen senere."]));
      });
  };
}

function henterMedarbejder() {
  return {
    type: constants.action.HENTER_MEDARBEJDER
  }
}

function hentMedarbejderSucces(response) {
  return {
    type: constants.action.HENT_MEDARBEJDER_SUCCES,
    medarbejder: response
  }
}

function hentMedarbejderFejl(errors) {
  return {
    type: constants.action.HENT_MEDARBEJDER_FEJL,
    alerts: {fejl: errors, info: []}
  }
}

export function hentEkstern(aktoerUuid) {

  return dispatch => {

    var url = `${constants.api.hentEksternUrl}`.replace("{aktoerUuid}", aktoerUuid);

    dispatch(henterEkstern());
    fetchJson(url, 'GET')
      .then((item) => {
        dispatch(hentEksternSucces(item))
      })
      .catch((errorResponse) => {
        if (errorResponse.status === 401)
          dispatch(hentEksternFejl(["401"]));
        else
          dispatch(hentEksternFejl(["Hentning af ekstern er fejlet, prøv igen senere."]));
      });
  };
}

function henterEkstern() {
  return {
    type: constants.action.HENTER_EKSTERN
  }
}

function hentEksternSucces(response) {
  return {
    type: constants.action.HENT_EKSTERN_SUCCES,
    ekstern: response
  }
}

function hentEksternFejl(errors) {
  return {
    type: constants.action.HENT_EKSTERN_FEJL,
    alerts: {fejl: errors, info: []}
  }
}


export function skjulAktoerModal() {
  return (dispatch) => {
    dispatch({
      type: constants.action.SKJUL_AKTOER_MODAL
    })
  }
}

export function soegOrganisationer(instnr) {
  return (dispatch, getState) => {
    dispatch({ type: constants.action.SOEGER_ORGANISATION });
    fetchJson(`${constants.api.soegOrganisationUrl}/${instnr}`,"GET")
        .then((json) => dispatch({ type: constants.action.SOEG_ORGANISTION_SUCCESS, orgnaisation: json }))
        .catch((errorResponse) => {
          const errors = errorResponse.status === 401 ? ["401"] : [`Kunne ikke finde institution med institutionsnummer: ${instnr}`]
          dispatch({ type: constants.action.SOEG_ORGANISTION_FEJL, alerts: {fejl: errors, info: []}});
        });
  };
}


export function hentKildestatistik(instRef) {
  return dispatch => {

    const url = `${constants.api.hentKildestatistikUrl}`.replace("{instRef}", instRef);

    dispatch(henterKildestatistik());
    fetchJson(url, 'GET')
        .then((item) => {
          dispatch(hentKildestatistikSucces(item))
        })
        .catch((errorResponse) => {
          if (errorResponse.status === 401)
            dispatch(hentKildestatistikFejl(["401"]));
          else
            dispatch(hentKildestatistikFejl(["Hentning af kildestatistik er fejlet, prøv igen senere."]));
        });
  };
}

function henterKildestatistik() {
  return {
    type: constants.action.HENTER_KILDESTATISTIK
  }
}

function hentKildestatistikSucces(response) {
  return {
    type: constants.action.HENT_KILDESTATISTIK_SUCCES,
    kildestatistik: response
  }
}

function hentKildestatistikFejl(errors) {
  return {
    type: constants.action.HENT_KILDESTATISTIK_FEJL,
    alerts: {fejl: errors, info: []}
  }
}

export function skjulKildestatistikModal() {
  return (dispatch) => {
    dispatch({
      type: constants.action.SKJUL_KILDESTATISTIK_MODAL
    })
  }
}