import React, {Component} from "react";
import CloseButton from "src/util/CloseButton.jsx";
import {formatTimestamp, naIfEmpty} from "src/util/util.js";

class AfidentificerModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled: false
        };
    }

    componentDidMount() {
        const {soegebrugere} = this.props;
        const institutionsListe = [];

        for (const {institutionRef} of soegebrugere) {
            institutionsListe.push(institutionRef);
        }

        const aktoerPaaKunEnInstitution = institutionsListe.every((institution, index, institutionsListe) => institution === institutionsListe[0])

        if (soegebrugere.length > 1 && !aktoerPaaKunEnInstitution) {
            this.setState({disabled: true});
        }
    }

    render() {
        const {visModal, lukModal, soegebrugere, afidentificerPerson} = this.props;
        let cpr;
        let uniId;

        if (soegebrugere[0] != null) {
            cpr = soegebrugere[0].cpr;
            uniId = soegebrugere[0].uniId;
        }

        if (!visModal)
            return null;

        function afidentificer(personUuid) {
            afidentificerPerson(personUuid)
            lukModal();
        }

        return (
            <div>
                <div className="fds-modal" id="afidentificer-modal" aria-hidden={!visModal} role="dialog"
                     aria-modal="true" aria-labelledby="afidentificer modal">
                    <div className="modal-content">
                        <div>
                            <p>Du er ved at afidentificere brugeren med <b>uniid: {uniId}</b> og <b>cpr: {cpr}</b>.</p>
                            <p> Bemærk at du herved sletter brugeren, uniid og alle tilknyttede aktører (roller):</p>
                            <CloseButton lukModal={lukModal}/>
                        </div>
                        <div className="modal-body">
                            <div className="modalcontent">
                                <div className="stil-col">
                                    {
                                        soegebrugere[0].aktoerUuid === "ingen uuid" ?
                                            <ul>
                                                <li>Brugeren har ingen tilknyttede aktører</li>
                                            </ul> :
                                            <table className="test-table stil-folding-table margtop10">
                                                <thead>
                                                <tr>
                                                    <th id="header-aktør">
                                                        Aktør&nbsp;
                                                    </th>

                                                    <th id="header-inst">
                                                        Institution&nbsp;
                                                    </th>

                                                    <th id="header-import">
                                                        Seneste import&nbsp;
                                                    </th>

                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    soegebrugere.map(soegebruger =>
                                                        <tr key={soegebruger.aktoerUuid}>
                                                            <td>{soegebruger.type}</td>
                                                            <td>{soegebruger.institutionRef}</td>
                                                            <td>{naIfEmpty(formatTimestamp(soegebruger.senesteImport))}</td>
                                                        </tr>)
                                                }
                                                </tbody>
                                            </table>
                                    }
                                </div>
                                {this.state.disabled &&
                                    <p className={"fremhaevet-tekst"}>Obs. brugere med aktører på tværs af institutioner
                                        kan ikke afidentificeres. Aktørerne skal først fjernes af institutionerne
                                        igennem deres import.</p>}
                            </div>
                        </div>
                        <div className="modal-footer" id={"modal-footer-afidentificer"}>
                            <button id="close-button" className="margleft10 stil-primary-button button"
                                    onClick={lukModal}
                                    title="Lukker dialogen">
                                Annuller
                            </button>
                            <button id="afidentificer-button" disabled={this.state.disabled}
                                    className="margleft10 stil-primary-button button"
                                    onClick={() => afidentificer(soegebrugere[0].personUuid)}
                                    title="Afidentificerer brugeren">
                                Afidentificer bruger
                            </button>
                        </div>
                    </div>
                </div>
                {visModal && <div className="modal-backdrop fade show"></div>}
            </div>
        )
    }
}

export default AfidentificerModal

