import React, {Component} from "react";

class AppBox extends Component {

  render() {
    const {title, elements} = this.props;
    return (
        <div className="app-box">
          <h4 className="app-box-title">{title}</h4>
          <hr/>
          {elements.map(element => <p key={element.url}><a target={element.nyTab ? "_blank" : undefined}
                                         rel={element.nyTab ? "noopener noreferrer" : undefined}
                                         href={element.url} title={element.title}>{element.text}</a></p>)}
        </div>
    )
  }
}

export default AppBox;