import React, {Component} from 'react';
import {Route, Routes} from 'react-router-dom';
import ForsideConnector from "src/containers/ForsideConnector.jsx";
import LoginConnector from "src/containers/LoginConnector.jsx";
import VersionConnector from "src/containers/VersionConnector.jsx";
import {constants} from "src/util/constants.js";
import SoegeConnector from "src/containers/SoegeConnector.jsx";
import InstitutionSoegningConnector from "src/containers/InstitutionSoegningConnector.jsx";
import BrugerSupportSoegningConnector from "src/containers/BrugerSupportSoegningConnector.jsx";

class Content extends Component {

  render() {

    // Login nulstiller token og viderestiller til BE-login. Dette er nødvendigt ved relogin,
    // da det nye token i query param ikke vil overskrive et token i session storage.
    // Logout viderestiller direkte til BE-logout, som vil viderestille tilbage til /?logout.
    // Hermed er det kun tilslutning, der behøver at kende til sin backend.

    return (
      <div className="stil-full-width">
        <Routes>
          <Route path="/" element={<ForsideConnector/>}/>
          <Route path={constants.navigation.urlLogin} element={<LoginConnector/>}/>
          <Route path={constants.navigation.urlVersion} element={<VersionConnector/>}/>
          <Route path={constants.navigation.urlSoegning} element={<SoegeConnector/>}/>
          <Route path={constants.navigation.urlInstitutionsSoegning} element={<InstitutionSoegningConnector/>}/>
          <Route path={constants.navigation.urlBrugerSoegning} element={<BrugerSupportSoegningConnector/>}/>
          <Route path={constants.navigation.urlLogout} element={<LoginConnector/>}/>
        </Routes>
      </div>
    )
  }
}

export default Content;
