import React, {Component} from 'react'
import Kilde from "src/pages/Kilde.jsx";

class InstitutionView extends Component {

  render() {
    const {kilder} = this.props;

    return (
        <section>
          <div className="stil-col">
            <h3>Kilder</h3>
            <table className="test-table stil-folding-table" id={"kildestatistik-table"}>
              <thead>
              <tr>
                <th id="header-kildenavn">Kilde</th>
                <th id="header-senesteimport">Antal Aktører</th>
                <th id="header-antalaktoerer">Seneste Import</th>
              </tr>
              </thead>
              <tbody>
              {
                kilder.map(kilde => {
                  return <Kilde key={kilde.kildeNavn} kilde={kilde}/>
                })
              }

              </tbody>
            </table>
          </div>
        </section>

    );
  }
}

export default InstitutionView;