import React, {Component} from 'react'
import {constants} from 'src/util/constants.js';
import SoegeForm from "src/pages/SoegeForm.jsx";
import AlertBar from "src/util/AlertBar.jsx";
import {Navigate} from "react-router-dom";
import KildestatistikModal from "src/modals/KildestatistikModal.jsx";

class InstitutionSoegningMain extends Component {

  render() {
    const {status, alerts, soegeOrganisationer, soegOrganisationer, supportadgang,
      hentKildestatistik, skjulKildestatistikModal, kildestatistik} = this.props;

      let title = "Vis institutionskilder";

    if (status === constants.status.supportadgangSuccess)
      return <Navigate to="/"/>;
    return (
        <div>
          <div className="stil-full-width">
            <section>
              <AlertBar alerts={alerts}/>
            </section>
            <div className="margtop30"/>
            <section>
              <SoegeForm id="institution-search" soeg={soegOrganisationer}
                         soeger={status === constants.status.soegerOrganisation}
                         titel={"Søg efter institutioner"}
                         placeholder={"Søg på institutionsnummer"}/>
            </section>
            <div className="margbottom10"/>
          </div>

          {
            soegeOrganisationer ?
                <div>
                  <section>
                    <div className="stil-col">
                      <h2 className="margtop30">Institutioner</h2>
                      <table className="test-table stil-folding-table margtop10">
                        <thead>
                        <tr>
                          <th id="header-instnr">Institutionsnummer</th>
                          <th id="header-instnavn">Institutionsnavn</th>
                          <th id="header-cvr">CVR</th>
                          <th id="header-pnr">PNR</th>
                          <th>&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                          soegeOrganisationer.map(organisation => {
                            return (
                                <tr className="institution-row hand" key={organisation.kode}>
                                  <td onClick={() => supportadgang(organisation.kode)}>{organisation.kode}</td>
                                  <td onClick={() => supportadgang(organisation.kode)}>{organisation.navn}</td>
                                  <td onClick={() => supportadgang(organisation.kode)}>{organisation.cvr}</td>
                                  <td onClick={() => supportadgang(organisation.kode)}>{organisation.pnr}</td>
                                    <td className="kilde-actions">
                                        <img className="detaljer-eye" title={title} src="img/ic_visibility_24px.svg"
                                             alt={title} style={{cursor: 'pointer'}} onClick={() => hentKildestatistik(organisation.kode)}/>
                                    </td>
                                </tr>
                            )
                          })
                        }
                        </tbody>
                      </table>
                    </div>
                      <KildestatistikModal kildestatistik={kildestatistik}
                                 visModal={kildestatistik !== null}
                                 lukModal={skjulKildestatistikModal}/>
                  </section>
                </div> :
                null
          }
        </div>
    );
  }
}

export default InstitutionSoegningMain;

