import React, {Component} from "react";

class Check extends Component {

  render() {
    const {boo} = this.props;

    if (!boo)
      return null;

    return (
      <img src="img/check.svg" alt=""/>
    )

  }
}

export default Check;