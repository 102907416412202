import React, {Component} from 'react'

class SoegeForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fritekst: ""
    };
    this.checkSubmit = this.checkSubmit.bind(this);
  }

  checkSubmit(event) {
      const buttonId = "button-" + (this.props.id ? this.props.id : "search");
      if (event.keyCode === 13)
        document.getElementById(buttonId).click();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
      const inputId = "input-" + (this.props.id ? this.props.id : "soegestreng");
      document.getElementById(inputId).focus();
  }

  render() {
    const {soeg, soeger, titel, placeholder, ryd, id} = this.props;
    const inputId = "input-" + (id ? id : "soegestreng");
    const buttonId = "button-" + (id ? id : "search");
    return (
      <div className="stil-col">
        <h1>{titel}</h1>
        <label htmlFor={inputId} className="label margtop10 margbottom10"></label>
        <input id={inputId} name="fritekst" placeholder={placeholder}
               className="input" autoComplete="off"
               disabled={soeger}
               value={this.state.fritekst}
               onChange={(event) => this.setState({fritekst: event.target.value})}
               onKeyUp={this.checkSubmit}
               autoFocus
        />

        <button id={buttonId}
                type="button"
                disabled={soeger|| this.state.fritekst.length < 1}
                onClick={() => soeg(this.state.fritekst, "", "asc", 0)}
                className={soeger || this.state.fritekst.length < 1 ? "button stil-disabled-button" : "hand button stil-primary-button"}
                title={this.state.fritekst.length < 1 ? "Der skal indtastes en søgestreng" : "Start søgningen"} >
          {soeger ? "Søger" : "Søg"}
        </button>

        {
          ryd ?
              <button id="button-clear"
                      type="button"
                      onClick={() =>{
                        this.setState({fritekst: ""});
                        ryd();
                      }}
                      className={soeger || this.state.fritekst.length < 1 ? "button stil-disabled-button" : "hand button stil-primary-button"}
                      title={"Ryd søgefeltet"} >
                Ryd søgning
              </button> :
              null
        }
      </div>
    );
  }
}

export default SoegeForm;