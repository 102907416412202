import React, {Component} from "react";
import CloseButton from "src/util/CloseButton.jsx";
import KildeView from "src/pages/KildeView.jsx";

class KildestatistikModal extends Component {

  render() {
    const {visModal, lukModal, kildestatistik} = this.props;

    if (!visModal)
      return null;

    return (

      <div>
        <div className="fds-modal" id="kildestatistik-modal" aria-hidden={!visModal} role="dialog"
             aria-modal="true" aria-labelledby="modal-example-title">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title" id="modal-example-title">Institution - {`${kildestatistik.institutionRef} - ${kildestatistik.institutionNavn}`}</h2>
              <CloseButton lukModal={lukModal}/>
            </div>
            <div className="modal-body">
              <div className="modalcontent">
                <div className="stil-col">
                <KildeView kilder={kildestatistik.kilder}/>
              </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="buttongroup">
                <button id="medarbejder-close-button" className="margleft10 stil-primary-button button" onClick={lukModal}
                        title="Lukker dialogen">
                  Luk
                </button>
              </div>
            </div>
          </div>
        </div>

        {visModal && <div className="modal-backdrop fade show"></div>}
      </div>
    )
  }
}

export default KildestatistikModal

