import React, {Component} from 'react'

class PageLink extends Component {

  render() {
    const { side, aktiv, soeg, soegestreng, sortfelt, sortdirection, naeste, forrige } = this.props;

    if (naeste === "true")
      return <span id="page-naeste" className="page-naeste" onClick={() => soeg(soegestreng, sortfelt, sortdirection, side)}>Næste</span>

    if (forrige === "true")
      return <span id="page-forrige" className="page-forrige" onClick={() => soeg(soegestreng, sortfelt, sortdirection, side)}>Forrige</span>

    if (aktiv !== "true")
      return <span id={"page-" + (side+1) + "-inactive"} className="page-inactive">{side + 1}</span>;

    return <span id={"page-" + (side+1)} className="page-active" onClick={() => soeg(soegestreng, sortfelt, sortdirection, side)}>{side + 1}</span>
  }
}

export default PageLink;
