import React, {Component} from 'react'
import PageLink from "src/pages/PageLink.jsx";

class Pagination extends Component {

  createPageLinks() {

    const {side, sidsteSide, soeg, soegestreng, sortfelt, sortdirection} = this.props;

    var start = sidsteSide > 10 && side > 5 ? side - 5 : 0;
    var slut = (start + 9) > sidsteSide - 1 ? sidsteSide - 1 : start + 9;

    if (slut > 9 && (slut - start) < 9)
      start = slut - 9;

    var pageLinks = [];
    if (side > 0){
      pageLinks.push(<PageLink key={crypto.randomUUID()} side={side - 1} aktiv="true" soeg={soeg} soegestreng={soegestreng} sortfelt={sortfelt} sortdirection={sortdirection} forrige="true"/>);
    }
    for (let i = start; i <= slut; i++) {
      if (i === side) {
        pageLinks.push(<PageLink key={crypto.randomUUID()} side={i} aktiv="false"/>);
      }
      else {
        pageLinks.push(<PageLink key={crypto.randomUUID()} side={i} aktiv="true" soeg={soeg} soegestreng={soegestreng} sortfelt={sortfelt}
                                 sortdirection={sortdirection}/>);
      }
    }

    if (side + 1 < sidsteSide){
      pageLinks.push(<PageLink key={crypto.randomUUID()} side={side + 1} aktiv="true" soeg={soeg} soegestreng={soegestreng} sortfelt={sortfelt}
                               sortdirection={sortdirection} naeste="true"/>);
    }
    return pageLinks;
  }

  render() {
    const {side, sidsteSide} = this.props;

    if (side === 0 && sidsteSide === 1)
      return <div className="bladring"/>

    return (
      <div className="bladring">
        {this.createPageLinks()}
      </div>
    );
  }
}

export default Pagination;
