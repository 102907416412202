import {constants} from 'src/util/constants.js';
import fetchJson from "src/util/fetch.js";

// Hent login-oplysninger. Funktionen kaldes fra redux-persist migration i configureStore.js.
const getAuthState = (state) => {
    return fetchJson(constants.navigation.urlBruger, 'GET')
        .then((bruger) => {
            return {
                ...state,
                login: {bruger: bruger, loggedIn: true},
                forside: {showSelectOrganisationModal: bruger.organisation === null}
            };
        }).catch(() => {
            return {
                ...state,
                login: constants.loginStateInit,
            };
        });
};

export default getAuthState;
