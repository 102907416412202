import {constants} from "src/util/constants.js";
import Cookie from 'js-cookie'

function setDefaults(method, jsonBody, body) {
    const init = {...constants.defaultFetchOptions, method: method, body: jsonBody};
    if ((method === 'POST' || method === 'DELETE')) {
        init.headers['X-XSRF-TOKEN'] = Cookie.get('XSRF-TOKEN');
    }
    if (body) {
        init.headers['Content-Type'] = 'application/json';
    }
    return init;
}

const fetchJson = (url, method, body) => {
    const jsonBody = body ? JSON.stringify(body) : undefined;
    const init = setDefaults(method, jsonBody, body);

    return fetch(url, init).then(response => {
        if (!response.ok) {
            throw response;
        }
        return response.json();
    });
};

export const fetchWithoutResponseBody = (url, method, body) => {
    const jsonBody = body ? JSON.stringify(body) : undefined;
    const init = setDefaults(method, jsonBody, body);

    return fetch(url, init).then(response => {
        if (!response.ok) {
            throw response;
        }
        return response;
    });
};
export default fetchJson;