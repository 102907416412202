import React, {Component} from "react";

function hideData(data) {
    const unicodeDot = "\u2022";
    return data ? unicodeDot.repeat(data.length) : "";
}

class BeskyttetDataComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataVises: false
        };
    }

render() {
    const {data, erSupport, erBeskyttet, erOverskrift, erKontaktperson} = this.props;
    const dataVises = (erSupport || !erBeskyttet) ? true : this.state.dataVises;

    if(erOverskrift){
        return (
            <span >
                {(!erSupport && erBeskyttet) &&
                    (<div className="field-eye-overskrift">
                        <p className="field-beskyttet">(Beskyttet) </p>
                        <img className="detaljer-eye-overskrift" src="img/ic_visibility_24px.svg" alt="Vis data" style={{cursor: 'hand'}}
                             onMouseOver={() => this.setState({dataVises: true})} onMouseOut={() => this.setState({dataVises: false})} />
                    </div>)
                }
                <div  className="field-data-overskrift">
                    <div className="field-overskrift">
                        {dataVises ? data : hideData(data)}
                    </div>
                </div>
            </span>
        );
    }

    if(erKontaktperson){
        return (
        <span>
            {(!erSupport && erBeskyttet) &&
                (<div className="field-eye">
                    <img className="detaljer-eye" title="Vis data" src="img/ic_visibility_24px.svg" alt="Vis data" style={{cursor: 'hand'}}
                         onMouseOver={() => this.setState({dataVises: true})} onMouseOut={() => this.setState({dataVises: false})} />
                </div>)
            }

            <div className="field-data-kontaktperson">
                {dataVises ? data : hideData(data)}
            </div>
        </span>
    );
    }

    return (
        <div>
            {(!erSupport && erBeskyttet) &&
                (<div className="field-eye">
                    <img className="detaljer-eye" title="Vis data" src="img/ic_visibility_24px.svg" alt="Vis data" style={{cursor: 'hand'}}
                         onMouseOver={() => this.setState({dataVises: true})} onMouseOut={() => this.setState({dataVises: false})} />
                </div>)
            }
            <div className="field-data">
                {dataVises ? data : hideData(data)}
            </div>
        </div>
    );
    }
}

export default BeskyttetDataComponent;
