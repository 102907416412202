import React, {Component} from 'react'
import AppBox from "src/pages/AppBox.jsx";
import AlertBar from "src/util/AlertBar.jsx";

class ForsideMain extends Component {

  constructor(props) {
    super(props);
  }


  render() { //NOSONAR
    const {forside, login, visOrganisationModal} = this.props;

    if (login.loggedIn && login.bruger && !login.bruger.organisation && !forside.showOrganisationModal)
      visOrganisationModal();

    return (
        <div>
          <AlertBar alerts={forside.alerts}/>
          <div className="stil-main-content">

            {login.loggedIn ? <ForsideEfterLogin login={login}/> : <ForsideFoerLogin/>}

          </div>
        </div>
    )
  }
}

function ForsideFoerLogin() {
  return (
      <div className="stil-full-width">
        <section>
          <div className="stil-col">
            <p>
              Brugeroversigten er skolens adgang til Styrelsen for It og lærings database, som indeholder de brugere, som skolerne og uddannelsesinstitutionerne har sendt til STIL med det formål at få oprettet et Unilogin til deres brugere.
            </p>
            <p>
              <a href="https://www.retsinformation.dk/eli/lta/2019/529">Uniloginbekendtgørelsen</a>
            </p>
          </div>
        </section>
      </div>
  )
}

function ForsideEfterLogin({login}) {
  return (
      <div className="stil-full-width">
        <section>
          <div className="stil-col">
            <div className="app-boxes">
              <AppBox title="Mine Brugere" elements={[
                {text: "Alle mine brugere", url: `soegning`, title: "Søg i alle institutionens brugere"},
              ]}/>
            </div>

            {
              (login.bruger && login.bruger.erSupport) ?
                  <AppBox title="Supportadgang" elements={[
                    {text: "Brugersøgning", url: 'bruger-soegning', title: "Søg efter brugeres institutionstilknytninger"},
                    {text: "Institutionssøgning", url: 'institutions-soegning', title: "Søg efter institutioner"}
                  ]}/> :
                  null
            }
          </div>
        </section>
      </div>
  )
}

export default ForsideMain;
