import React, {Component} from 'react';
import Content from "src/Content.jsx";
import Footer from "src/util/Footer.jsx";
import HeaderConnector from "src/util/HeaderConnector.jsx";

class App extends Component {

  render() {
    return (
      <div className="stil-site clearfix">
        <HeaderConnector />
        <Content />
        <Footer />
      </div>
    );
  }
}

export default App;
