import {constants} from "src/util/constants.js";
import fetchJson from "src/util/fetch.js";


export function opretBrugeradministrator(aktoerUuid) {
  return dispatch => {
    var url = `${constants.api.brugeradministratorUrl}`.replace("{aktoerUuid}", aktoerUuid);

    dispatch(opdatererBrugeradm());
    fetchJson(url, 'POST')
        .then((item) => {
          dispatch(opretBrugeradmSucces(item, ["Succesfuld oprettelse af brugeradministrator"]))
        })
        .catch(() => {
            dispatch(opretBrugeradmFejl(["Oprettelse af brugeradministrator fejlede"]));
        });
  };
}

function opdatererBrugeradm() {
  return {
    type: constants.action.OPDATERER_BRUGERADM
  }
}

function opretBrugeradmSucces(response, successBesked) {
  return {
    type: constants.action.OPRET_BRUGERADM_SUCCESS,
    aktoerErBrugeradm: response,
    detaljesideAlerts: {fejl: []},
    detaljesideSuccess: {success: successBesked}
  }
}

function opretBrugeradmFejl(errors) {
  return {
    type: constants.action.OPRET_BRUGERADM_FEJL,
    detaljesideAlerts: {fejl: errors},
    detaljesideSuccess: {success: []}
  }
}

export function sletBrugeradministrator(aktoerUuid) {
  return dispatch => {
    var url = `${constants.api.brugeradministratorUrl}`.replace("{aktoerUuid}", aktoerUuid);

    dispatch(opdatererBrugeradm());
    fetchJson(url, 'DELETE')
        .then((item) => {
          dispatch(sletBrugeradmSucces(item, ["Succesfuld sletning af brugeradministrator"]))
        })
        .catch((errorResponse) => {
          dispatch(sletBrugeradmFejl(["Sletning af brugeradministrator fejlede"]));
        });
  };
}

function sletBrugeradmSucces(response, successBesked) {
  return {
    type: constants.action.SLET_BRUGERADM_SUCCESS,
    aktoerErBrugeradm: response,
    detaljesideAlerts: {fejl: []},
    detaljesideSuccess: {success: successBesked}
  }
}

function sletBrugeradmFejl(errors) {
  return {
    type: constants.action.SLET_BRUGERADM_FEJL,
    detaljesideAlerts: {fejl: errors},
    detaljesideSuccess: {success: []}
  }
}