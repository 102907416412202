import React from 'react';

export default function SortingChevron(props) {
  const {active, direction, basedir} = props;
  let basedirVar = "";
  if(basedir){
  	basedirVar = basedir;
  }
  if(active){
    if(direction === 'asc')
      return (<img src={basedirVar+"img/less.svg"} className="margbottom-5" alt="asc"/>);
    else
      return (<img src={basedirVar+"img/more.svg"} className="margbottom-5" alt="desc"/>);
  } else{
    return (<img src={basedirVar+"img/right.svg"} className="margbottom-5" alt=""/>);
  }
}
