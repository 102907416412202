import React, {Component} from "react";

function formatCpr(cpr) {
    if (!cpr)
        return "";
    return cpr.length !== 10 ? cpr : cpr.substring(0, 6) + "-" + cpr.substring(6,10);
}

function hideCpr(cpr) {
    const unicodeDot = "\u2022";
    return cpr ? unicodeDot.repeat(cpr.length) : "";
}

class CprNummerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cprVises: false
        };
    }

render() {
    const {cprNr, erSupport} = this.props;
    const cprVises = erSupport ? true : this.state.cprVises;


    return (
        <>
            {!erSupport &&
                (<div className="field-eye">
                    <img className="detaljer-eye" title="Vis CPR nummer" src="img/ic_visibility_24px.svg" alt="Vis CPR nummer" style={{cursor: 'hand'}}
                         onMouseOver={() => this.setState({cprVises: true})} onMouseOut={() => this.setState({cprVises: false})} />
                </div>)
            }

            <div className={(erSupport ? "field-data-cpr-vises" : "field-data-cpr-skjult") + " field-cpr"}>
                {cprVises ? formatCpr(cprNr) : hideCpr(formatCpr(cprNr))}
            </div>
        </>
    );
    }
}

export default CprNummerComponent;
