import {constants} from "src/util/constants.js";

export function loginReducer(state = [], action) {

  switch (action.type) {
    case constants.action.LOGIN_CLEAR:
      return constants.loginStateInit;

    case constants.action.HENTER_ORGANISATIONER:
      return {...state, status: constants.status.henterOrganisationer};

    case constants.action.HENT_ORGANISATIONER_SUCCESS:
      return {...state, organisationer: action.organisationer, status: constants.status.hentOrganisationerSuccess};

    case constants.action.HENT_ORGANISATIONER_FEJL:
      return {...state, alerts: action.alerts, organisationer: [], status: constants.status.hentOrganisationerFejl};

    case constants.action.SKIFTER_ORGANISATION:
      return {...state, status: constants.status.skifterOrganisation};

    case constants.action.SKIFT_ORGANISATION_SUCCESS:
      return {...state, bruger: action.bruger, status: constants.status.skiftOrganisationSuccess};

    case constants.action.SKIFT_ORGANISATION_FEJL:
      return {...state,  alerts: action.alerts, status: constants.status.skiftOrganisationFejl};

    case constants.action.GIVER_SUPPORTADGANG:
      return {...state, status: constants.status.giverSupportadgang};

    case constants.action.SUPPORTADGANG_SUCCESS:
      return {...state, bruger: action.bruger, status: constants.status.supportadgangSuccess};

    case constants.action.SUPPORTADGANG_FEJL:
      return {...state,  alerts: action.alerts, status: constants.status.supportadgangFejl};

    default:
      return state;
  }
}
