import {combineReducers} from 'redux'
import {forsideReducer} from "src/reducers/ForsideReducer.js";
import {loginReducer} from "src/reducers/LoginReducer.js";
import {soegeReducer} from "src/reducers/SoegeReducer.js";
import {versionReducer} from "src/reducers/VersionReducer.js";

export default combineReducers({
  forside: forsideReducer,
  login: loginReducer,
  soegning: soegeReducer,
  version: versionReducer
});
