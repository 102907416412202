import React, {Component} from 'react'
import {formatTimestamp} from 'src/util/util.js'

class SoegeBrugerSupport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            harDetaljevisning: false,
            institutionRefOgNavn: ""
        };
    }

    componentDidMount() {
        const {soegebruger} = this.props
        if (soegebruger.institutionRef !== null && soegebruger.type !== "Kontaktperson") {
            this.setState({harDetaljevisning: true})
        }
        if (soegebruger.institutionRef !== null) {
            this.setState({institutionRefOgNavn: soegebruger.institutionRef + " " + soegebruger.institutionNavn})
        }
    }


    componentDidUpdate(prevProps) {
        if ((this.props.soegebruger !== prevProps.soegebruger)) {
            if (this.props.soegebruger.institutionRef !== null && this.props.soegebruger.type !== "Kontaktperson") {
                this.setState({harDetaljevisning: true})
            } else {
                this.setState({harDetaljevisning: false})
            }
            if (this.props.soegebruger.institutionRef !== null) {
                this.setState({institutionRefOgNavn: this.props.soegebruger.institutionRef + " " + this.props.soegebruger.institutionNavn})
            }
        }
    }
    hentAktoer(aktoerUuid, type) {
        const {hentElev, hentMedarbejder, hentEkstern} = this.props;

        if (type === "Elev") {
            hentElev(aktoerUuid);
        } else if (type === "Medarbejder") {
            hentMedarbejder(aktoerUuid);
        } else if (type === "Ekstern") {
            hentEkstern(aktoerUuid);
        }
    }

    skiftInstitution(institutionRef) {
        const {supportadgang} = this.props;

        if (institutionRef != null) {
            supportadgang(institutionRef)
        }
    }

    render() {
        const {soegebruger} = this.props;

        let title = "Vis elev detaljer";
        if (soegebruger.type === "Medarbejder") {
            title = "Vis medarbejder detaljer";
        } else if (soegebruger.type === "Ekstern") {
            title = "Vis ekstern detaljer";
        }

        return (
            <tr className="bruger-row hand">
                <td onClick={() => this.skiftInstitution(soegebruger.institutionRef)}>{soegebruger.navn}</td>
                <td onClick={() => this.skiftInstitution(soegebruger.institutionRef)}>{soegebruger.uniId}</td>
                <td onClick={() => this.skiftInstitution(soegebruger.institutionRef)}>{this.state.institutionRefOgNavn}</td>
                <td onClick={() => this.skiftInstitution(soegebruger.institutionRef)}>{soegebruger.klasse}</td>
                <td onClick={() => this.skiftInstitution(soegebruger.institutionRef)}>{soegebruger.type}</td>
                <td onClick={() => this.skiftInstitution(soegebruger.institutionRef)}>{formatTimestamp(soegebruger.sidstAendret)}</td>
                <td className="bruger-actions">
                    {this.state.harDetaljevisning === true &&
                        <img className="detaljer-eye" title={title} src="img/ic_visibility_24px.svg"
                             alt={title} style={{cursor: 'pointer'}}
                             onClick={() => this.hentAktoer(soegebruger.aktoerUuid, soegebruger.type)}/>
                    }
                </td>
            </tr>
        );
    }
}

export default SoegeBrugerSupport;