import React, {Component} from 'react'
import {formatTimestamp, naIfEmpty} from 'src/util/util.js'

class Kilde extends Component {

  render() {
    const {kilde} = this.props;

    return (
      <tr>
        <td>{kilde.kildeNavn}</td>
        <td>{kilde.antalAktoererPaaKilde}</td>
        <td>{naIfEmpty(formatTimestamp(kilde.senestKoerteImport))}</td>
      </tr>
    );
  }
}

export default Kilde;