import {connect} from 'react-redux'
import * as actions from "src/actions/soegning.js";
import * as loginActions from "src/actions/login.js";
import InstitutionSoegningMain from "src/containers/InstitutionSoegningMain.jsx";

const mapStateToProps = (state) => {
  return state.soegning;
};

const mapDispatchToProps  = (dispatch) => {
  return {
    soegOrganisationer: (instnr) => dispatch(actions.soegOrganisationer(instnr)),
    supportadgang: (instRef) => dispatch(loginActions.supportadgang(instRef)),
    hentKildestatistik: (instRef) => dispatch(actions.hentKildestatistik(instRef)),
    skjulKildestatistikModal: () => dispatch(actions.skjulKildestatistikModal())
  }
};

const InstitutionSoegningConnector = connect(mapStateToProps, mapDispatchToProps)(InstitutionSoegningMain);
export default InstitutionSoegningConnector;
