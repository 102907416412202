import React, {Component} from 'react'
import {formatTimestamp} from 'src/util/util.js'

class Gruppe extends Component {

  render() {
    const {gruppe} = this.props;

    return (
      <tr>
        <td>{gruppe.gruppekode}</td>
        <td>{gruppe.navn}</td>
        <td>{gruppe.gruppetype}</td>
        <td>{gruppe.klassetype}</td>
        <td>{gruppe.gruppetrin}</td>
        <td>{formatTimestamp(gruppe.oprettet_ts)}</td>
        <td>{formatTimestamp(gruppe.aendret_ts)}</td>
      </tr>
    );
  }
}

export default Gruppe;