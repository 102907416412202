import React, {Component} from "react";

class FeedbackBox extends Component {

    render() {
        const {detaljesideAlerts, feedbackBesked} = this.props;
        return (
            <div>
                {detaljesideAlerts.fejl.length > 0 ?
                    <div className="alert alert-error">
                        <div className="alert-body">
                            <p className="alert-text">{feedbackBesked}</p>
                        </div>
                    </div>
                    : <div className="alert alert-success">
                        <div className="alert-body">
                            <p className="alert-text">{feedbackBesked}</p>
                        </div>
                    </div>}
            </div>
        )
    }
}
export default FeedbackBox;